import { resolveProgramSlug } from '@wix/ambassador-challenges-v1-challenge/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  getChallengeSlugFromLocation,
  getLocationFromFlowAPI,
} from '../../Location/locationProviderPropsMap';
import requestChallenge from './api/requestChallenge';

let resolveSlugResponsePromise: Promise<{ data?: { programId?: string } }>;

export const resolveProgramId = async (
  flowAPI: ControllerFlowAPI,
): Promise<{
  programId: string;
  programData?: Awaited<ReturnType<typeof requestChallenge>>;
}> => {
  if (resolveSlugResponsePromise) {
    return { programId: (await resolveSlugResponsePromise).data.programId };
  }
  const programId = getProgramIdFromQueryParams(flowAPI);
  if (programId) {
    return { programId };
  }
  resolveSlugResponsePromise = flowAPI.httpClient.request(
    resolveProgramSlug({ slug: getChallengeSlugFromLocation(flowAPI) }),
  );
  return { programId: (await resolveSlugResponsePromise)?.data?.programId };
};

function getProgramIdFromQueryParams(flowAPI: ControllerFlowAPI) {
  return getLocationFromFlowAPI(flowAPI).query?.programId;
}

export const clearResolvedProgramIdCache = () => {
  resolveSlugResponsePromise = undefined;
};
